/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import AccountHero from './components/account-hero/AccountHero';

export const sections = {
  CREATE_ACCOUNT: '39QCc9HzoYt6iEPYjfh0Yo',
};

const pageSpecificMap = {
  [sections.CREATE_ACCOUNT]: ({ title, headline, body, body2, primaryButton, mediaContent }) => (
    <AccountHero
      key={sections.CREATE_ACCOUNT}
      title={title}
      subtitle={headline}
      termsCopy={body?.body}
      loginCTACopy={body2?.body2}
      createCTA={primaryButton}
      mediaContent={mediaContent}
    />
  ),
};

export default pageSpecificMap;
