import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const AccountHeroStyled = styled.section.attrs({
  className: 'AccountHeroStyled',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_3};
    padding: 0;
  }
`;

export const AccountHeroInner = styled.div.attrs({
  className: 'AccountHeroInner',
})`
  .FormWrapper {
    width: 100%;
    max-width: 34rem;
    margin: auto;
  }

  @media ${breakPoints.large_break} {
    display: flex;
    flex-direction: row-reverse;

    .FormWrapper {
      width: 50%;
      max-width: 35rem;
      margin: unset;
    }
  }
`;

export const ImageHeroWrapper = styled.div.attrs({
  className: 'ImageHeroWrapper',
})`
  display: none;

  @media ${breakPoints.large_break} {
    width: 50%;
    display: block;
    height: auto;
    position: relative;
    min-height: 30rem;

    .Image {
      height: 100%;
      position: absolute;

      picture {
        img {
          position: absolute;
          width: auto;
          height: 100%;
          min-height: 35rem;
        }
      }
    }
  }
`;
