import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage } from 'elements';

import { AccountHeroStyled, AccountHeroInner, ImageHeroWrapper } from './AccountHero.styled';
import AccountForm from '../account-form/AccountForm';

const AccountHero = ({ title, subtitle, termsCopy, loginCTACopy, createCTA, mediaContent }) => {
  return (
    <AccountHeroStyled>
      <ContentSection>
        <AccountHeroInner>
          <ImageHeroWrapper>
            <GatsbyImage image={mediaContent?.gatsbyImageData} alt={mediaContent?.description} />
          </ImageHeroWrapper>
          <AccountForm title={title} subtitle={subtitle} termsCopy={termsCopy} loginCTACopy={loginCTACopy} createCTA={createCTA} />
        </AccountHeroInner>
      </ContentSection>
    </AccountHeroStyled>
  );
};

AccountHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  termsCopy: PropTypes.string,
  loginCTACopy: PropTypes.string,
  createCTA: PropTypes.object,
  mediaContent: PropTypes.object,
};

export default AccountHero;
