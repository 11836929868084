import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

const ELEMENTS_MAX_WIDTH_MOBILE = '86%';
const ELEMENTS_MAX_WIDTH_DESKTOP = '28rem';

export const FormWrapper = styled.div.attrs({
  className: 'FormWrapper',
})`
  padding: 2rem 0;

  .Button {
    width: 100%;
    max-width: 15.5rem;
    height: 2.25rem;
    margin: auto;
    margin-top: 1.75rem;
    margin-bottom: 1.5rem;
  }

  a {
    color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  @media ${breakPoints.large_break} {
    padding: 4rem 0 3rem;
  }
`;

export const FormGroup = styled.div.attrs({
  className: 'FormGroup',
})`
  .InputStyled {
    margin: 1.5rem auto;

    max-width: ${ELEMENTS_MAX_WIDTH_MOBILE};
    div {
      width: 100%;
      max-width: 100%;
    }
  }

  .component__Checkbox {
    max-width: ${ELEMENTS_MAX_WIDTH_MOBILE};
    margin: auto;
    align-items: flex-start;

    .checkbox__IconContainer {
      margin-top: 0.188rem;
    }

    .Text {
      text-align: left;
    }
  }

  @media ${breakPoints.large_break} {
    .InputStyled {
      max-width: ${ELEMENTS_MAX_WIDTH_DESKTOP};
    }

    .component__Checkbox {
      max-width: ${ELEMENTS_MAX_WIDTH_DESKTOP};
    }
  }
`;

export const TextWrapper = styled.div.attrs({
  className: 'TextWrapper',
})`
  margin: auto;
  padding: 0 1.25rem;

  .Title {
    letter-spacing: 0.1rem;
    margin-bottom: 0.5rem;
  }

  @media ${breakPoints.large_break} {
    .Title {
      font-size: 3.125rem;
      line-height: 1.1;
      font-weight: 300;
    }

    .Text {
      font-size: 1rem;
    }
  }
`;
