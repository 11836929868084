import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

import { FormWrapper, FormGroup, TextWrapper } from './AccountForm.styled';

import { getCreateAccountPayload, getValidationSchema, initialFormValues } from './FormHelpers';
import { Text, Title, SquaredButton, Input, Checkbox, Link } from 'elements';
import { useAuth, useStopLoading } from 'utils/hooks';
import { getContentMessages } from 'app/reduxState/contentful';
import { userCreateAccount, getLoginState, setUserValue } from 'app/reduxState/user';
import { FormikEmailInput } from 'components';
import { toPhoneNumber } from 'utils/pattern-utils';
import { hasErrorOnTouchedFields } from 'utils/form-utils';
import paths from 'constants/paths';

const emailInUseError = (
  <>
    Sorry, an account with the entered email address already exists.{' '}
    <Link href={paths.LOGIN} modifiers={['smallBig', 'underline']}>
      Login now.
    </Link>
  </>
);

const AccountForm = ({ title, subtitle, termsCopy, loginCTACopy, createCTA }) => {
  const isLoggedIn = useSelector(getLoginState);
  const dispatch = useDispatch();
  const auth = useAuth();
  const messages = useSelector(getContentMessages);
  const searchLocation = useLocation().search;
  const { redirect } = queryString.parse(searchLocation);

  useStopLoading(!!Object.values(messages).length);

  useEffect(() => {
    if (isLoggedIn) redirect ? navigate(redirect) : navigate(paths.MY_FARM);
  }, [isLoggedIn, redirect]);

  const handleSubmit = (values, { setSubmitting }) => {
    const onCreateAccountError = () => {
      setSubmitting(false);
    };

    const payload = getCreateAccountPayload(values);
    dispatch(
      userCreateAccount({ auth, email: values.email, password: values.password, accountData: payload, onError: onCreateAccountError })
    );
  };

  return (
    <FormWrapper>
      <FormGroup>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={getValidationSchema(messages)}
        >
          {({ setFieldValue, errors, touched, isSubmitting, submitCount, isValid }) => {
            const isSubmitDisabled = (submitCount > 0 && !isValid) || hasErrorOnTouchedFields({ errors, touched });
            const onEmailBlur = (_, { email }) => {
              dispatch(setUserValue({ label: 'email', value: email }));
            };
            const onPhoneChange = (event) => {
              setFieldValue('phone', toPhoneNumber(event.target.value));
            };
            return (
              <Form noValidate>
                <TextWrapper>
                  <Title as='h2' content={title} modifiers={['secondarySmall', 'center']} />
                  <Text content={subtitle} modifiers={['center']} />
                </TextWrapper>
                <Field
                  required
                  variant='filled'
                  name='name'
                  type='text'
                  label='First Name'
                  autoComplete='given-name'
                  helperText='*Required'
                  component={Input}
                />
                <Field
                  required
                  variant='filled'
                  name='lastName'
                  type='text'
                  label='Last Name'
                  autoComplete='family-name'
                  helperText='*Required'
                  component={Input}
                />
                <FormikEmailInput
                  required
                  variant='filled'
                  name='email'
                  label='Email'
                  helperText='*Required'
                  autoComplete='email'
                  onBlur={onEmailBlur}
                  emailInUseError={emailInUseError}
                />
                <Field
                  required
                  variant='filled'
                  name='password'
                  type='password'
                  label='Password'
                  helperText='*Required'
                  component={Input}
                />
                <Field
                  variant='filled'
                  name='phone'
                  label='Phone Number'
                  component={Input}
                  autoComplete='tel'
                  inputMode='numeric'
                  onChange={onPhoneChange}
                />
                <Field
                  name='termsCheck'
                  component={Checkbox}
                  modifiers='leftAlign'
                  label={<Text content={termsCopy} modifiers={['light', 'brandFont']} isHTML />}
                />
                <SquaredButton
                  type='submit'
                  label={createCTA.linkText}
                  disabled={isSubmitDisabled}
                  modifiers={[isSubmitDisabled && 'disabled', isSubmitting && 'loading']}
                />
                <Text content={loginCTACopy} modifiers={['light', 'brandFont', 'center']} isHTML />
              </Form>
            );
          }}
        </Formik>
      </FormGroup>
    </FormWrapper>
  );
};

AccountForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  termsCopy: PropTypes.string,
  loginCTACopy: PropTypes.string,
  createCTA: PropTypes.object,
};

export default AccountForm;
