import React from 'react';
import PropTypes from 'prop-types';

import { CreateAccountStyled } from './CreateAccount.styled';
import { SectionProvider } from 'components';

import pageSpecificMap from './SectionsRenderer';

const CreateAccount = ({ data }) => {
  const sections = data?.contentfulLandingPage?.sections || [];

  return (
    <CreateAccountStyled>
      <SectionProvider sectionContent={sections} customSectionMap={pageSpecificMap} isGraphqlData />
    </CreateAccountStyled>
  );
};

CreateAccount.propTypes = {
  data: PropTypes.object,
};

export default CreateAccount;
