import * as Yup from 'yup';

import { phoneNumberRegex } from 'utils/pattern-utils';
import { dbFormatPhone } from 'utils/form-utils';

export const initialFormValues = {
  name: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  termsCheck: false,
};

const REQ_MSG = 'Please complete all required fields.';

export const getValidationSchema = (messages) => {
  return Yup.object().shape({
    name: Yup.string().required(messages?.LGE_required || REQ_MSG),
    lastName: Yup.string().required(messages?.LGE_required || REQ_MSG),
    password: Yup.string().required(messages?.LGE_required || REQ_MSG),
    phone: Yup.string().matches(phoneNumberRegex, messages?.LG_invalid_phone_number),
    termsCheck: Yup.bool().equals([true]),
    email: Yup.string().required(messages?.LGE_required || REQ_MSG),
  });
};

export const getCreateAccountPayload = (formValues, customerType = 'LG_ADHOC', taxStatus = 'NON_EXEMPT') => {
  const { name, lastName, email, phone } = formValues;
  const payload = {
    email,
    address: { name: `${name} ${lastName}` },
    customerType,
    taxStatus,
    ...(phone ? { phone: dbFormatPhone(phone.trim()) } : {}),
  };

  return payload;
};
