import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import CreateAccountPage from 'staticPages/create-account/CreateAccount';

const CreateAccount = ({ data }) => {
  return <CreateAccountPage data={data} />;
};

CreateAccount.propTypes = {
  data: PropTypes.object,
};

export default CreateAccount;

export const query = graphql`
  query {
    contentfulLandingPage(contentful_id: { eq: "4xNm61XvugEEoXjpjwKn86" }) {
      slug
      contentful_id
      sections {
        ... on ContentfulSectionSimpleText {
          contentful_id
          title
          headline
          ...SimpleTextBodyFragment
          primaryButton {
            ... on ContentfulCallToAction {
              linkText
            }
          }
          mediaContent {
            ...AssetFullWidthFragment
          }
        }
      }
    }
  }
`;
